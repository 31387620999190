
var recaptchaScriptLoaded = false;
var recaptchaScriptLoaded_page = false;
var dynRecaptcha = {};
var get_recaptcha_container;
var have_recaptcha;

(function jquery(){
    if(window.$){


        $(document).ready(function () {
            /************ $(document).ready ************/

            $(document).on('shown.bs.modal', function () {
                get_recaptcha_container = setInterval(get_recaptcha, 1000);
            });







            //$(document).on('hidden.bs.modal', function (modal) {

                //var form = $(modal.target).find("form");
                //var alias = form.data("alias");
                //console.log(eval("dynRecaptcha['widget_" + alias + "']"));
                //grecaptcha.reset(eval("dynRecaptcha['widget_" + alias + "']"));
                //$(modal.target).remove();
                //$(".recaptcha_window_container").remove();
            //});






            
            



            $.fn.prepare_recaptcha();

            $('body').on('submit','.result_hidden form',function(event) {
            //$(".result_hidden form").submit(function (event) {



                event.preventDefault();


                var target = $(event.target).data('alias'), form = $('form[data-alias="' + target + '"]'),
                    button_text = $("button[type=submit]", form).text(),
                    widget = "dynRecaptcha['widget_" + target + "']";

                form.attr('data-submit-please', 'true');

                $("button[type=submit]", form).prop("disabled", !0).text(button_wait).attr("data-oldtext", button_text);

                $(".recaptcha_backdrop").unbind("click");
                $('.recaptcha_backdrop').on('click',function() {
                    console.log("close recaptcha window");
                    $("button[type=submit]", form).prop("disabled", !1).text(button_text);
                })


                grecaptcha.execute(eval(widget));

            });


            /************ $(document).ready ************/
        });


    }else {
        setTimeout(jquery, 100);
    }
})();






function get_recaptcha() {
    var search = $("body").not(".recaptcha_window").find("div[style*='visibility: hidden'][style*='absolute'][style*='top: -10000px']"),
        search_length = search.length;

    //console.log("get_recaptcha");

    search.addClass("recaptcha_window_container").children("div[style*='0px auto'][style*='overflow: hidden']").addClass("recaptcha_window").end().children("div[style*='fixed'][style*='width: 100%'][style*='height: 100%']").addClass("recaptcha_backdrop")


    var search_modify = $("body").find("div.recaptcha_window_container[style*='hidden'][style*='absolute'][style*='-10000px']"),
        search_modify_length = search_modify.length;

    if (($("body").hasClass("get_recaptcha") && search_modify_length == have_recaptcha) || !$("body").hasClass("get_recaptcha")) {
        //console.log("clearInterval get_recaptcha")
        $("body").removeClass("get_recaptcha")
        $("body").removeAttr("data-recaptcha")
        clearInterval(get_recaptcha_container);
    }

}





// при открытии modal и наличии там формы надо запускать: get_recaptcha_container=setInterval(get_recaptcha,1000);






function prepare_one_recaptcha(){
    $("body").addClass("get_recaptcha");
    $("body").attr('data-recaptcha', '1');
    get_recaptcha_container=setInterval(get_recaptcha,1000);
}


window.recaptcha_submit=function(token){

    //console.log(token);

    return new Promise(function (resolve, reject) {

        //document.getElementById('captcha').value = token;

        var c = $('form[data-submit-please="true"]');
        var fix = c.find("#modal_id").val();
        var b = c.data("alias");
        var w = "dynRecaptcha['widget_" + b + "']";

        $(c).removeAttr('data-submit-please', 'true');

        //$(c).find('input[name="g-recaptcha-response"]').val(token);
        $("form[data-alias='"+b+"']").find('textarea[name="g-recaptcha-response"]').val(token)


        //.g-recaptcha-response
        //, "g-recaptcha-response":token

        return c.ajaxSubmit({
            dataType: "json", url: "/index.php?module=contactsnew", data: {alias_id: b, action: "validate"},type: 'POST',

            beforeSubmit: function () {
                button_text = $("button[type=submit]", c).data("oldtext");
            }, success: function (a) {


                $("form[data-alias='"+b+"']").find(".form-alert").remove(),

                a.form.is_valid === !1 && ($("button[type=submit]", c).prop("disabled", !1),


                $("form[data-alias='"+b+"']").prepend('<div class="alert form-alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><h4>' + a.form.title + '</h4><ul class="content_error"></ul></div>'),

                $.fn.form_popover("show", a.fields, $("form[data-alias='"+b+"']")),
                $("button[type=submit]", c).text(button_text)),
                grecaptcha.reset(eval(w));

                prepare_one_recaptcha();

                a.form.is_valid === !0 && (

                    $("button[type=submit]", c).text(button_text),
                    $("#" + fix).find(".result_hidden").fadeOut2(),
                    $("#" + fix).find(".result_open").fadeIn2().html(a.form.finish_tpl), $(":input", c).not(":button, :submit, :reset, :hidden").val(""),
                    c.find("input[type=file].multi").MultiFile("reset")
                );
                    //"21" == b && yaCounter50188036.reachGoal("calculate_1"),
                    //"22" == b && yaCounter50188036.reachGoal("calculate_2"),
                    //"23" == b && yaCounter50188036.reachGoal("calculate_3"),
                    //"24" == b && yaCounter50188036.reachGoal("calculate_4"))
            }
        }), !1

    }); //end promise

}



window.reviews_recaptcha_submit = function(token) {

    //console.log(token);

    return new Promise(function (resolve, reject) {

        //document.getElementById('captcha').value = token;

        var c = $('form[data-submit-please="true"]');
        var fix = c.find("#modal_id").val();
        var b = c.data("alias");
        var w = "dynRecaptcha['widget_" + b + "']";

        $(c).removeAttr('data-submit-please', 'true');

        //$(c).find('input[name="g-recaptcha-response"]').val(token);
        $("form[data-alias='"+b+"']").find('textarea[name="g-recaptcha-response"]').val(token)


        //.g-recaptcha-response
        //, "g-recaptcha-response":token

        return c.ajaxSubmit({
            dataType: "json", url: "/index.php?module=guestbook&action=new&ajax=1", data: {alias_id: b, action: "validate"},type: 'POST',

            beforeSubmit: function () {
                button_text = $("button[type=submit]", c).data("oldtext");
            }, success: function (a) {


                $("form[data-alias='"+b+"']").find(".form-alert").remove(),

                a.form.is_valid === !1 && ($("button[type=submit]", c).prop("disabled", !1),


                    $("form[data-alias='"+b+"']").prepend('<div class="alert form-alert alert-danger alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><h4>' + a.form.title + '</h4><ul class="content_error"></ul></div>'),

                    $.fn.form_popover("show", a.fields, $("form[data-alias='"+b+"']")),
                    $("button[type=submit]", c).text(button_text)),
                    grecaptcha.reset(eval(w));

                prepare_one_recaptcha();

                a.form.is_valid === !0 && (

                    $("button[type=submit]", c).text(button_text),
                        $("#" + fix).find(".result_hidden").fadeOut2(),
                        $("#" + fix).find(".result_open").fadeIn2().html(a.form.finish_tpl), $(":input", c).not(":button, :submit, :reset, :hidden").val(""),
                        c.find("input[type=file].multi").MultiFile("reset")
                );
                //"21" == b && yaCounter50188036.reachGoal("calculate_1"),
                //"22" == b && yaCounter50188036.reachGoal("calculate_2"),
                //"23" == b && yaCounter50188036.reachGoal("calculate_3"),
                //"24" == b && yaCounter50188036.reachGoal("calculate_4"))
            }
        }), !1

    }); //end promise

}






window.recaptchaOnload_page = function () {
    recaptchaScriptLoaded_page = true;
}






// это отвечает за формы на странице?
/*
!(function ($) {
    $.fn.extend({
        prepare_recaptcha: function (modal) {

            setTimeout(function () {

                var captcha_length = $("div.captcha_wrapper").length;

                //console.log($("div.captcha_wrapper"));

                if (captcha_length > 0) {
                    $("body").addClass("get_recaptcha");
                    $("body").attr('data-recaptcha', captcha_length);

                    $.getScript('https://www.google.com/recaptcha/api.js?onload=recaptchaOnload_page&render=explicit', function () {
                        try {
                            (function recaptcha_page() {
                                if (recaptchaScriptLoaded_page) {


                                        $(".recaptcha_window_container").remove();
                                        $("div.captcha_wrapper").each(function (item) {

                                            var form = $(this).parents("form");
                                            var alias = form.data("alias");
                                            var button_text = $("button[type=submit]", form).text();
                                            var data_action = form.data("action")
                                            var action = (data_action !== undefined) ? data_action : "recaptcha_submit";


                                            $(this).append("<div id='recaptcha_" + alias + "'></div>");

                                            dynRecaptcha['widget_' + alias] = grecaptcha.render('recaptcha_' + alias, {
                                                'sitekey': '6LeriuYUAAAAAC_J1ZoeQ6EjyOfSWYxFLi27m14S',
                                                'size': 'invisible',
                                                'callback': action
                                            });
                                        });


                                    have_recaptcha = captcha_length;
                                    clearInterval(get_recaptcha_container);
                                    get_recaptcha_container = setInterval(get_recaptcha, 1000);
                                    //console.log("prepare_recaptcha");

                                }
                                else {
                                    setTimeout(recaptcha_page, 200);
                                }
                            })();


                        } catch (e) {
                            console.log(e)
                        }
                    });
                }
            }, 500);
        }
    });
})(jQuery);
*/

!(function ($) {
    $.fn.extend({
        prepare_recaptcha: function (modal) {
            setTimeout(function () {
                var captcha_length = $("div.captcha_wrapper").length;
                if (captcha_length > 0) {
                    $("body").addClass("get_recaptcha");
                    $("body").attr("data-recaptcha", captcha_length);
                    $.getScript("https://www.google.com/recaptcha/api.js?onload=recaptchaOnload_page&render=explicit", function () {
                        try {
                            (function recaptcha_page() {
                                if (recaptchaScriptLoaded_page) {
                                    if (modal) {
                                        //console.log("modal")
                                    }
                                    else {
                                        $(".recaptcha_window_container").remove();
                                        $("div.captcha_wrapper").each(function (item) {
                                            var form = $(this).parents("form");
                                            var alias = form.data("alias");
                                            var button_text = $("button[type=submit]", form).text();
                                            var data_action = form.data("action");
                                            var action = data_action !== undefined ? data_action : "recaptcha_submit";
                                            $(this).append("<div id='recaptcha_" + alias + "'></div>");
                                            dynRecaptcha["widget_" + alias] = grecaptcha.render("recaptcha_" + alias, {
                                                sitekey: "6LeriuYUAAAAAC_J1ZoeQ6EjyOfSWYxFLi27m14S",
                                                size: "invisible",
                                                callback: action
                                            })
                                        })
                                    }
                                    have_recaptcha = captcha_length;
                                    clearInterval(get_recaptcha_container);
                                    get_recaptcha_container = setInterval(get_recaptcha, 1e3);



                                    //console.log("prepare_recaptcha")
                                }
                                else {
                                    setTimeout(recaptcha_page, 200)
                                }
                            })()
                        } catch (e) {
                            console.log(e)
                        }
                    })
                }
            }, 500)
        }
    })
})(jQuery);



window.recaptchaOnload = function () {
    recaptchaScriptLoaded = true;
}



!(function($) {
    $.fn.extend({
        prepare_modal: function(alias, action, widget) {


            //console.log(action);

            var form = $('form[data-alias="' + alias + '"]');
            var button_text = $("button[type=submit]", form).text()
            var myaction = (action && action.length) ? action : "recaptcha_submit";
            $("button[type=submit]", form).prop("disabled", !0).text(button_wait);



            $.getScript('https://www.google.com/recaptcha/api.js?onload=recaptchaOnload&render=explicit', function() {
                try {

                    (function get_recaptcha() {

                        if (recaptchaScriptLoaded) {

                            $(".captcha_wrapper", form).attr("data-recaptcha", widget).append("<div class='g-recaptcha' id='recaptcha_" + alias + "' data-sitekey='6LeriuYUAAAAAC_J1ZoeQ6EjyOfSWYxFLi27m14S' data-size='invisible' data-callback='" + myaction + "'></div>");
                            widget = grecaptcha.render('recaptcha_' + alias + '');
                            $("button[type=submit]", form).prop("disabled", !1).text(button_text);

                        } else {

                            setTimeout(get_recaptcha, 200);

                        }
                    })();

                } catch (e) {
                    console.log(e)
                }
            });


            //console.log(alias);
            //console.log('#' + alias);
            $('#' + alias).on('hidden.bs.modal', function() {

                $('#recaptcha_' + alias + '', form).remove();
                var button_text = $("button[type=submit]", form).text();
                $("button[type=submit]", form).prop("disabled", !1).text(button_text);
                $('#' + alias).remove();
                $(".recaptcha_window_container").remove();
                clearInterval(get_recaptcha_container);
            });


            //$(document).on('hidden.bs.modal', function (modal) {
            //var form = $(modal.target).find("form");
            //var alias = form.data("alias");
            //console.log(eval("dynRecaptcha['widget_" + alias + "']"));
            //grecaptcha.reset(eval("dynRecaptcha['widget_" + alias + "']"));
            //$(modal.target).remove();
            //$(".recaptcha_window_container").remove();
            //});




        }
    });
})(jQuery);




