
import './src/jquery.migrate.js';

import './src/jquery.easing.js';
//import './src/jquery-scrolltofixed.js';
//import './src/ResizeSensor.js';
import './src/jquery.sticky-sidebar.js';
import './src/jquery.collapse.js';
//import './src/background-check.js';

// import './src/jquery.flexslider.js';
// import './src/ion.rangeSlider.js';

import './src/common_form.js';
import './src/hyphenation.js';
//import './src/jquery.scroolly.js';

//import './src/jquery.fancybox.js';
import './src/jquery.fancybox4.js';
import './src/shadow-animation.js';
import './src/jquery-confirm.js';
//import './src/jquery-chosen.js'
//import './src/jquery.magicsuggest.min.js'
import './src/jquery.suggestions.min.js'
import './src/intl-tel-input.js'
import './src/jquery.youtubebackground.js'
// import './src/jquery-confirm.js';
// import './src/touchswipe.js';
// import './src/jquery.form.plugin.js';
// import './src/jquery.masked.input.plugin.js';

import './src/common.js';
import './src/bottom.js';

import 'bootstrap/js/alert';
import 'bootstrap/js/carousel';
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/transition';

import 'slick-carousel';
import 'lazysizes';
//import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
//import 'lazysizes/plugins/parent-fit/ls.parent-fit';



//import jBox from "jbox";
import './src/jquery.form.plugin.js'
//import './src/packery.pkgd.min.js';


//import 'jbox/dist/plugins/jBox.Notice';
//import 'jbox/dist/jBox';

