
var top_count = 0;
var news_count = 0;
var review_count = 0;
var product_buy_count = 0;




$(window).on('load', function(){

    $('.carousel.carousel_disable').each(function(){
        $(this).carousel('pause');
    });

});



(function jquery(){
    if(window.$){
        "use strict";


        if(jQuery.browser.mobile){
            $(".carousel-inner").swipe({

                tap:function(event, target) {

                },


                swipeLeft: function(t, e, n, s, a) {
                    $(this).parent().carousel("next")
                },
                swipeRight: function() {
                    $(this).parent().carousel("prev")
                },

                threshold: 10

            });
        }


        $("#top_carousel .item").each(function(item){
            ++top_count;
        });

        $("#news_carousel .item").each(function(item){
            ++news_count;
        });

        $("#reviews_carousel .item").each(function(item){
            ++review_count;
        });

        $("#product_buy .item").each(function(item){
            ++product_buy_count;
        });



    }else {
        setTimeout(jquery, 100);
    }
})();



    function create_carousel(container, count_three, count_item){


        $(container+" .item").each(function () {
            for (var t = $(this), e = 1; count_item > e; e++)
                t = t.next(),
                t.length || (t = $(this).siblings(":first")),
                    t.children(":first-child").clone().addClass("cloneditem cloneditem-" + e).appendTo($(this))
        })



        $(container).addClass('carousel_create');
        //$(container+" .item").not(".active").children().removeClass("wow").css({"visibility": "visible"});

        if(container == "#top_carousel") {
            $(container+ " .products_block_top").equalizeHeights_slider(function(){
                $(this).find(".fix_caption").remove()
            });
        }


        if(container == "#news_carousel") {
            $(container+" .news_block").equalizeHeights_slider(function(){
                $(this).find(".fix_caption").remove()
                $(this).addClass("in");
                $(container).css({"background":"none"})
            });
        }

        if(container == "#reviews_carousel") {
            $(container+" .news_block").equalizeHeights_slider(function(){
                $(this).addClass("in");
            });
        }

    }





    function three_shows(a,b,c,d,e,f){a<b||(d>e||d==e)?
        $(c).hasClass("carousel_shows")||
        (

                $(c+" .item").removeClass("active"),
                $(c+" .item").eq(0).addClass("active"),
                $(c).hasClass("carousel_create")||create_carousel(c,d,e),
                $(c).removeClass("carousel_disable").addClass("carousel_shows"),
                $(c+" .item").removeClass("fixvisible"),
                $(c+" .carousel-inner").swipe("enable"),
                //$(c).data("bs.carousel").options.interval=f,
                $(c).attr('data-interval', f),

                //$(c).carousel("cycle"),
                $(c+" + .carousel-control").show(),
                $(c+" + .carousel-control + .carousel-control").show(),
                $(c).on("slide.bs.carousel",function(e) {$(c+" .item").children().removeClass("wow animated bounceInLeft bounceInRight bounceInUp bounceInDown").css({"visibility": "visible"});})

        ):
        $(c).hasClass("carousel_disable")||
        (

            $(c).carousel("pause"),
                $(c).removeClass("carousel_shows").removeClass("carousel_create").addClass("carousel_disable"),
                //$(c+" .item").addClass("fixvisible").children(".cloneditem-1,.cloneditem-2,.cloneditem-3,cloneditem-4").remove(),
                $(c+" .item").addClass("fixvisible").children(".cloneditem-1,.cloneditem-2,.cloneditem-3,cloneditem-4").remove(),
                $(c+" .carousel-inner").swipe("disable"),
                $(c+" + .carousel-control").hide(),
                $(c+" + .carousel-control + .carousel-control").hide()
        )

        // imagesLoaded($(c+" .fix_caption"), function() {
        //     $(c+" .products_block_top, "+c+" .news_block").equalizeHeights(function(){
        //         //$(".products_bl").css({"background":"none"})
        //         $(this).addClass("in")
        //         $(c+" .brazzers").brazzersCarousel();
        //         $(c+" .fix_caption").remove()
        //     });
        // });

    }



function create_carusel(){

    //$(window).on('resize', function(){


            if(top_count > 2){
                three_shows(outerWidth, '992', '#top_carousel', top_count, '3', 400000, "0");
            }else if(top_count > 1){
                three_shows(outerWidth, '551', '#top_carousel', top_count, '3', 400000, "0");
            }


            if(news_count > 2){
                three_shows(outerWidth, '992', '#news_carousel', news_count, '3', 500000, "0");
            }else if(news_count > 1){
                three_shows(outerWidth, '551', '#news_carousel', news_count, '3', 500000, "0");
            }





            if(review_count > 2){
                three_shows(outerWidth, '992', '#reviews_carousel', review_count, '3', 500000, "0");
            }else if(review_count > 1){
                three_shows(outerWidth, '551', '#reviews_carousel', review_count, '3', 500000, "0");
            }



            if(product_buy_count > 2){
                three_shows(outerWidth, '992', '#product_buy', product_buy_count, '4', 2000, "1");
            }else if(product_buy_count > 0){
                three_shows(outerWidth, '551', '#product_buy', product_buy_count, '4', 2000, "1");
            }




    //});


}





$(window).on('resize', function() {

    create_carusel();

});





