/*!
* jquery-confirm v3.2.0 (http://craftpip.github.io/jquery-confirm/)
*/
if ("undefined" == typeof jQuery) {
    throw new Error("jquery-confirm requires jQuery");
}
var jconfirm, Jconfirm;
!function (t, n) {
    "use strict";
    t.fn.confirm = function (n, i) {
        return void 0 === n && (n = {}), "string" == typeof n && (n = {
            content: n, title: i || !1
        }), t(this).each(function () {
            var i = t(this);
            i.on("click", function (o) {
                o.preventDefault();
                var s = t.extend({}, n);
                if (i.attr("data-title") && (s.title = i.attr("data-title")), i.attr("data-content") && (s.content = i.attr("data-content")), void 0 === s.buttons && (s.buttons = {}), s.$target = i, i.attr("href") && 0 == Object.keys(s.buttons).length) {
                    var e = t.extend(!0, {}, jconfirm.pluginDefaults.defaultButtons, (jconfirm.defaults || {}).defaultButtons || {}),
                        a = Object.keys(e)[0];
                    s.buttons = e, s.buttons[a].action = function () {
                        location.href = i.attr("href")
                    }
                }
                s.closeIcon = !1, t.confirm(s)
            })
        }), t(this)
    }, t.confirm = function (n, i) {
        if (void 0 === n && (n = {}), "string" == typeof n && (n = {
            content: n, title: i || !1
        }), "object" != typeof n.buttons && (n.buttons = {}), 0 == Object.keys(n.buttons).length) {
            var o = t.extend(!0, {}, jconfirm.pluginDefaults.defaultButtons, (jconfirm.defaults || {}).defaultButtons || {});
            n.buttons = o
        }
        return jconfirm(n)
    }, t.alert = function (n, i) {
        if (void 0 === n && (n = {}), "string" == typeof n && (n = {
            content: n, title: i || !1
        }), "object" != typeof n.buttons && (n.buttons = {}), 0 == Object.keys(n.buttons).length) {
            var o = t.extend(!0, {}, jconfirm.pluginDefaults.defaultButtons, (jconfirm.defaults || {}).defaultButtons || {}),
                s = Object.keys(o)[0];
            n.buttons[s] = o[s]
        }
        return jconfirm(n)
    }, t.dialog = function (t, n) {
        return void 0 === t && (t = {}), "string" == typeof t && (t = {
            content: t, title: n || !1, closeIcon: function () {
            }
        }), t.buttons = {}, void 0 === t.closeIcon && (t.closeIcon = function () {
        }), t.confirmKeys = [13], jconfirm(t)
    }, jconfirm = function (n) {
        void 0 === n && (n = {});
        var i = t.extend(!0, {}, jconfirm.pluginDefaults);
        jconfirm.defaults && (i = t.extend(!0, i, jconfirm.defaults)), i = t.extend(!0, {}, i, n);
        var o = new Jconfirm(i);
        return jconfirm.instances.push(o), o
    }, (Jconfirm = function (n) {
        t.extend(this, n), this._init()
    }).prototype = {
        _init: function () {
            var n = this;
            jconfirm.instances.length || (jconfirm.lastFocused = t("body").find(":focus")), this._id = Math.round(99999 * Math.random()), setTimeout(function () {
                n.open()
            }, 0)
        },
        _buildHTML: function () {
            var n = this;
            this._parseAnimation(this.animation, "o"), this._parseAnimation(this.closeAnimation, "c"), this._parseBgDismissAnimation(this.backgroundDismissAnimation), this._parseColumnClass(this.columnClass), this._parseTheme(this.theme), this._parseType(this.type);
            var i = t(this.template);
            i.find(".jconfirm-box").addClass(this.animationParsed).addClass(this.backgroundDismissAnimationParsed).addClass(this.typeParsed), this.typeAnimated && i.find(".jconfirm-box").addClass("jconfirm-type-animated"), this.useBootstrap ? (i.find(".jc-bs3-row").addClass(this.bootstrapClasses.row), i.find(".jc-bs3-row").addClass("justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center"), i.find(".jconfirm-box-container").addClass(this.columnClassParsed), this.containerFluid ? i.find(".jc-bs3-container").addClass(this.bootstrapClasses.containerFluid) : i.find(".jc-bs3-container").addClass(this.bootstrapClasses.container)) : i.find(".jconfirm-box").css("width", this.boxWidth), this.titleClass && i.find(".jconfirm-title-c").addClass(this.titleClass), i.addClass(this.themeParsed);
            var o = "jconfirm-box" + this._id;
            i.find(".jconfirm-box").attr("aria-labelledby", o).attr("tabindex", -1), i.find(".jconfirm-content").attr("id", o), null != this.bgOpacity && i.find(".jconfirm-bg").css("opacity", this.bgOpacity), this.rtl && i.addClass("jconfirm-rtl"), this.$el = i.appendTo(this.container), this.$jconfirmBoxContainer = this.$el.find(".jconfirm-box-container"), this.$jconfirmBox = this.$body = this.$el.find(".jconfirm-box"), this.$jconfirmBg = this.$el.find(".jconfirm-bg"), this.$title = this.$el.find(".jconfirm-title"), this.$titleContainer = this.$el.find(".jconfirm-title-c"),
                this.$content = this.$el.find("div.jconfirm-content"),
                this.$contentPane = this.$el.find(".jconfirm-content-pane"),
                this.$icon = this.$el.find(".jconfirm-icon-c"),
                this.$closeIcon = this.$el.find(".jconfirm-closeIcon"),
                this.$btnc = this.$el.find(".jconfirm-buttons"), this.$scrollPane = this.$el.find(".jconfirm-scrollpane"), this._contentReady = t.Deferred(), this._modalReady = t.Deferred(), this.setTitle(), this.setIcon(), this._setButtons(), this._parseContent(), this.initDraggable(), this.isAjax && this.showLoading(!1), t.when(this._contentReady, this._modalReady).then(function () {
                n.isAjaxLoading ? setTimeout(function () {
                    n.isAjaxLoading = !1, n.setContent(), n.setTitle(), n.setIcon(), setTimeout(function () {
                        n.hideLoading(!1)
                    }, 100), "function" == typeof n.onContentReady && n.onContentReady()
                }, 50) : (n.setContent(), n.setTitle(), n.setIcon(), "function" == typeof n.onContentReady && n.onContentReady()), n.autoClose && n._startCountDown()
            }), n._contentHash = this._hash(n.$content.html()), n._contentHeight = this.$content.height(), this._watchContent(), this.setDialogCenter(), "none" == this.animation && (this.animationSpeed = 1, this.animationBounce = 1), this.$body.css(this._getCSS(this.animationSpeed, this.animationBounce)),
                this.$contentPane.css(this._getCSS(this.animationSpeed, 1)),
                this.$jconfirmBg.css(this._getCSS(this.animationSpeed, 1))
        },
        _typePrefix: "jconfirm-type-",
        typeParsed: "",
        _parseType: function (t) {
            this.typeParsed = this._typePrefix + t
        },
        setType: function (t) {
            var n = this.typeParsed;
            this._parseType(t), this.$jconfirmBox.removeClass(n).addClass(this.typeParsed)
        },
        themeParsed: "",
        _themePrefix: "jconfirm-",
        setTheme: function (t) {
            var n = this.theme;
            this.theme = t || this.theme, this._parseTheme(this.theme), n && this.$el.removeClass(n), this.$el.addClass(this.themeParsed), this.theme = t
        },
        _parseTheme: function (n) {
            var i = this;
            n = n.split(","), t.each(n, function (o, s) {
                -1 == s.indexOf(i._themePrefix) && (n[o] = i._themePrefix + t.trim(s))
            }), this.themeParsed = n.join(" ").toLowerCase()
        },
        backgroundDismissAnimationParsed: "",
        _bgDismissPrefix: "jconfirm-hilight-",
        _parseBgDismissAnimation: function (n) {
            var i = n.split(","), o = this;
            t.each(i, function (n, s) {
                -1 == s.indexOf(o._bgDismissPrefix) && (i[n] = o._bgDismissPrefix + t.trim(s))
            }), this.backgroundDismissAnimationParsed = i.join(" ").toLowerCase()
        },
        animationParsed: "",
        closeAnimationParsed: "",
        _animationPrefix: "jconfirm-animation-",
        setAnimation: function (t) {
            this.animation = t || this.animation, this._parseAnimation(this.animation, "o")
        },
        _parseAnimation: function (n, i) {
            i = i || "o";
            var o = n.split(","), s = this;
            t.each(o, function (n, i) {
                -1 == i.indexOf(s._animationPrefix) && (o[n] = s._animationPrefix + t.trim(i))
            });
            var e = o.join(" ").toLowerCase();
            return "o" == i ? this.animationParsed = e : this.closeAnimationParsed = e, e
        },
        setCloseAnimation: function (t) {
            this.closeAnimation = t || this.closeAnimation, this._parseAnimation(this.closeAnimation, "c")
        },
        setAnimationSpeed: function (t) {
            this.animationSpeed = t || this.animationSpeed
        },
        columnClassParsed: "",
        setColumnClass: function (t) {
            this.columnClass = t || this.columnClass, this._parseColumnClass(this.columnClass), this.$jconfirmBoxContainer.addClass(this.columnClassParsed)
        },
        _parseColumnClass: function (t) {
            var n;
            switch (t = t.toLowerCase()) {
                case"xl":
                case"xlarge":
                    n = "col-md-12";
                    break;
                case"l":
                case"large":
                    n = "col-md-8 col-md-offset-2";
                    break;
                case"m":
                case"medium":
                    n = "col-md-6 col-md-offset-3";
                    break;
                case"s":
                case"small":
                    n = "col-md-4 col-md-offset-4";
                    break;
                case"xs":
                case"xsmall":
                    n = "col-md-2 col-md-offset-5";
                    break;
                default:
                    n = t
            }
            this.columnClassParsed = n
        },
        initDraggable: function () {
            var i = this, o = this.$titleContainer;
            this.resetDrag(), this.draggable && (o.addClass("jconfirm-hand"), o.on("mousedown", function (t) {
                i.mouseX = t.clientX, i.mouseY = t.clientY, i.isDrag = !0
            }), t(n).on("mousemove." + this._id, function (t) {
                i.isDrag && (i.movingX = t.clientX - i.mouseX + i.initialX, i.movingY = t.clientY - i.mouseY + i.initialY, i.setDrag())
            }), t(n).on("mouseup." + this._id, function () {
                i.isDrag && (i.isDrag = !1, i.initialX = i.movingX, i.initialY = i.movingY)
            }))
        },
        resetDrag: function () {
            this.isDrag = !1, this.initialX = 0, this.initialY = 0, this.movingX = 0, this.movingY = 0, this.movingXCurrent = 0, this.movingYCurrent = 0, this.mouseX = 0, this.mouseY = 0, this.$jconfirmBoxContainer.css("transform", "translate(0px, 0px)")
        },
        setDrag: function () {
            if (this.draggable) {
                this.alignMiddle = !1, this._boxWidth = this.$jconfirmBox.outerWidth();
                var i = t(n).width(), o = this;
                if (o.movingX % 2 == 0 || o.movingY % 2 == 0) {
                    var s = o._boxTopMargin - o.dragWindowGap;
                    s + o.movingY < 0 ? o.movingY = -s : o.movingYCurrent = o.movingY;
                    var e = i / 2 - o._boxWidth / 2, a = i / 2 + o._boxWidth / 2 - o._boxWidth;
                    a -= o.dragWindowGap, (e -= o.dragWindowGap) + o.movingX < 0 ? o.movingX = -e : a - o.movingX < 0 ? o.movingX = a : o.movingXCurrent = o.movingX, o.$jconfirmBoxContainer.css("transform", "translate(" + o.movingX + "px, " + o.movingY + "px)")
                }
            }
        },
        _hash: function (t) {
            var n = t.toString(), i = 0;
            if (0 == n.length) {
                return i;
            }
            for (var o = 0; o < n.length; o++) i = (i << 5) - i + n.toString().charCodeAt(o), i &= i;
            return i
        },
        _watchContent: function () {
            var t = this;
            this._timer && clearInterval(this._timer), this._timer = setInterval(function () {
                var n = t._hash(t.$content.html()), i = t.$content.height();
                t._contentHash == n && t._contentHeight == i || (t._contentHash = n, t._contentHeight = i, t.setDialogCenter(), t._imagesLoaded())
            }, this.watchInterval)
        },
        _hilightAnimating: !1,
        _hiLightModal: function () {
            var t = this;
            if (!this._hilightAnimating) {
                t.$body.addClass("hilight");
                this._hilightAnimating = !0, setTimeout(function () {
                    t._hilightAnimating = !1, t.$body.removeClass("hilight")
                }, 2e3)
            }
        },
        _bindEvents: function () {
            var i = this;
            this.boxClicked = !1, this.$scrollPane.click(function (t) {
                if (!i.boxClicked) {
                    var n, o = !1, s = !1;
                    if (n = "function" == typeof i.backgroundDismiss ? i.backgroundDismiss() : i.backgroundDismiss, "string" == typeof n && void 0 !== i.buttons[n] ? (o = n, s = !1) : s = void 0 === n || 1 == !!n, o) {
                        var e = i.buttons[o].action.apply(i);
                        s = void 0 === e || !!e
                    }
                    s ? i.close() : i._hiLightModal()
                }
                i.boxClicked = !1
            }), this.$jconfirmBox.click(function (t) {
                i.boxClicked = !0
            });
            var o = !1;
            t(n).on("jcKeyDown." + i._id, function (t) {
                o || (o = !0, console.log("keydown" + o))
            }), t(n).on("keyup." + i._id, function (t) {
                o && (i.reactOnKey(t), o = !1)
            }), t(n).on("resize." + this._id, function () {
                i.setDialogCenter(!0), setTimeout(function () {
                    i.resetDrag()
                }, 100)
            })
        },
        _cubic_bezier: "0.36, 0.55, 0.19",
        _getCSS: function (t, n) {
            return {
                "-webkit-transition-duration": t / 1e3 + "s",
                "transition-duration": t / 1e3 + "s",
                "-webkit-transition-timing-function": "cubic-bezier(" + this._cubic_bezier + ", " + n + ")",
                "transition-timing-function": "cubic-bezier(" + this._cubic_bezier + ", " + n + ")"
            }
        },
        _imagesLoaded: function () {
            var n = this;
            n.imageLoadInterval && clearInterval(n.imageLoadInterval), t.each(this.$content.find("img:not(.loaded)"), function (i, o) {
                n.imageLoadInterval = setInterval(function () {
                    "0px" !== t(o).css("height") && (t(o).addClass("loaded"), clearInterval(n.imageLoadInterval), n.setDialogCenter())
                }, 40)
            })
        },
        _setButtons: function () {
            var n = this, i = 0;
            if ("object" != typeof this.buttons && (this.buttons = {}), t.each(this.buttons, function (o, s) {
                i += 1, "function" == typeof s && (n.buttons[o] = s = {action: s}), n.buttons[o].text = s.text || o, n.buttons[o].btnClass = s.btnClass || "btn-default", n.buttons[o].action = s.action || function () {
                }, n.buttons[o].keys = s.keys || [], t.each(n.buttons[o].keys, function (t, i) {
                    n.buttons[o].keys[t] = i.toLowerCase()
                });
                var e = t('<button type="button" class="btn ' + n.buttons[o].btnClass + '">' + n.buttons[o].text + "</button>").click(function (t) {
                    t.preventDefault();
                    var i = n.buttons[o].action.apply(n);
                    n.onAction(o), n._stopCountDown(), (void 0 === i || i) && n.close()
                });
                n.buttons[o].el = e, n.buttons[o].setText = function (t) {
                    e.html(t)
                }, n.buttons[o].addClass = function (t) {
                    e.addClass(t)
                }, n.buttons[o].removeClass = function (t) {
                    e.removeClass(t)
                }, n.buttons[o].disable = function () {
                    e.prop("disabled", !0)
                }, n.buttons[o].enable = function () {
                    e.prop("disabled", !1)
                }, n.buttons[o].show = function () {
                    e.css("display", ""), n.setDialogCenter()
                }, n.buttons[o].hide = function () {
                    e.css("display", "none"), n.setDialogCenter()
                }, n["$_" + o] = n["$$" + o] = e, n.$btnc.append(e)
            }), 0 === i && this.$btnc.hide(), null === this.closeIcon && 0 === i && (this.closeIcon = !0), this.closeIcon) {
                if (this.closeIconClass) {
                    var o = '<i class="' + this.closeIconClass + '"></i>';
                    this.$closeIcon.html(o)
                }
                this.$closeIcon.click(function (t) {
                    t.preventDefault();
                    var i, o = !1, s = !1;
                    if (i = "function" == typeof n.closeIcon ? n.closeIcon() : n.closeIcon, "string" == typeof i && void 0 !== n.buttons[i] ? (o = i, s = !1) : s = void 0 === i || 1 == !!i, o) {
                        var e = n.buttons[o].action.apply(n);
                        s = void 0 === e || !!e
                    }
                    s && n.close()
                }), this.$closeIcon.show()
            }
            else {
                this.$closeIcon.hide()
            }
        },
        setTitle: function (t, n) {
            if (n = n || !1, void 0 !== t) {
                if ("string" == typeof t) {
                    this.title = t;
                }
                else if ("function" == typeof t) {
                    "function" == typeof t.promise && console.error("Promise was returned from title function, this is not supported.");
                    var i = t();
                    this.title = "string" == typeof i && i
                }
                else {
                    this.title = !1;
                }
            }
            this.isAjaxLoading && !n || this.$title.html(this.title || "")
        },
        setIcon: function (t, n) {
            if (n = n || !1, void 0 !== t) {
                if ("string" == typeof t) {
                    this.icon = t;
                }
                else if ("function" == typeof t) {
                    var i = t();
                    this.icon = "string" == typeof i && i
                }
                else {
                    this.icon = !1;
                }
            }
            this.isAjaxLoading && !n || this.$icon.html(this.icon ? '<i class="' + this.icon + '"></i>' : "")
        },
        setContentPrepend: function (t, n) {
            this.contentParsed = t + this.contentParsed, this.isAjaxLoading && !n || this.$content.prepend(t)
        },
        setContentAppend: function (t, n) {
            this.contentParsed = this.contentParsed + t, this.isAjaxLoading && !n || this.$content.append(t)
        },
        setContent: function (t, n) {
            n = n || !1;
            var i = this;
            this.contentParsed = void 0 === t ? this.contentParsed : t, this.isAjaxLoading && !n || (this.$content.html(this.contentParsed), this.setDialogCenter(), setTimeout(function () {
                i.$body.find("input[autofocus]:visible:first").focus()
            }, 100))
        },
        loadingSpinner: !1,
        showLoading: function (t) {
            this.loadingSpinner = !0, this.$jconfirmBox.addClass("loading"), t && this.$btnc.find("button").prop("disabled", !0), this.setDialogCenter()
        },
        hideLoading: function (t) {
            this.loadingSpinner = !1, this.$jconfirmBox.removeClass("loading"), t && this.$btnc.find("button").prop("disabled", !1), this.setDialogCenter()
        },
        ajaxResponse: !1,
        contentParsed: "",
        isAjax: !1,
        isAjaxLoading: !1,
        _parseContent: function () {
            var n = this, i = "&nbsp;";
            if ("function" == typeof this.content) {
                var o = this.content.apply(this);
                "string" == typeof o ? this.content = o : "object" == typeof o && "function" == typeof o.always ? (this.isAjax = !0, this.isAjaxLoading = !0, o.always(function (t, i, o) {
                    n.ajaxResponse = {
                        data: t, status: i, xhr: o
                    }, n._contentReady.resolve(t, i, o), "function" == typeof n.contentLoaded && n.contentLoaded(t, i, o)
                }), this.content = i) : this.content = i
            }
            if ("string" == typeof this.content && "url:" === this.content.substr(0, 4).toLowerCase()) {
                this.isAjax = !0, this.isAjaxLoading = !0;
                var s = this.content.substring(4, this.content.length);
                t.get(s).done(function (t) {
                    n.contentParsed = t
                }).always(function (t, i, o) {
                    n.ajaxResponse = {
                        data: t, status: i, xhr: o
                    }, n._contentReady.resolve(t, i, o), "function" == typeof n.contentLoaded && n.contentLoaded(t, i, o)
                })
            }
            this.content || (this.content = i), this.isAjax || (this.contentParsed = this.content, this.setContent(this.contentParsed), n._contentReady.resolve())
        },
        _stopCountDown: function () {
            clearInterval(this.autoCloseInterval), this.$cd && this.$cd.remove()
        },
        _startCountDown: function () {
            var n = this, i = this.autoClose.split("|");
            if (2 !== i.length) {
                return console.error("Invalid option for autoClose. example 'close|10000'"), !1;
            }
            var o = i[0], s = parseInt(i[1]);
            if (void 0 === this.buttons[o]) {
                return console.error("Invalid button key '" + o + "' for autoClose"), !1;
            }
            var e = s / 1e3;
            this.$cd = t('<span class="countdown"> (' + e + ")</span>").appendTo(this["$_" + o]), this.autoCloseInterval = setInterval(function () {
                n.$cd.html(" (" + (e -= 1) + ") "), 0 === e && (n["$$" + o].trigger("click"), n._stopCountDown())
            }, 1e3)
        },
        _getKey: function (t) {
            switch (t) {
                case 192:
                    return "tilde";
                case 13:
                    return "enter";
                case 16:
                    return "shift";
                case 9:
                    return "tab";
                case 20:
                    return "capslock";
                case 17:
                    return "ctrl";
                case 91:
                    return "win";
                case 18:
                    return "alt";
                case 27:
                    return "esc";
                case 32:
                    return "space"
            }
            var n = String.fromCharCode(t);
            return !!/^[A-z0-9]+$/.test(n) && n.toLowerCase()
        },
        reactOnKey: function (n) {
            var i = this, o = t(".jconfirm");
            if (o.eq(o.length - 1)[0] !== this.$el[0]) {
                return !1;
            }
            var s = n.which;
            if (this.$content.find(":input").is(":focus") && /13|32/.test(s)) {
                return !1;
            }
            var e = this._getKey(s);
            if ("esc" === e && this.escapeKey) {
                if (!0 === this.escapeKey) {
                    this.$scrollPane.trigger("click");
                }
                else if ("string" == typeof this.escapeKey || "function" == typeof this.escapeKey) {
                    var a;
                    (a = "function" == typeof this.escapeKey ? this.escapeKey() : this.escapeKey) && (void 0 === this.buttons[a] ? console.warn("Invalid escapeKey, no buttons found with key " + a) : this["$_" + a].trigger("click"))
                }
            }
            t.each(this.buttons, function (t, n) {
                -1 != n.keys.indexOf(e) && i["$_" + t].trigger("click")
            })
        },
        _boxTopMargin: 0,
        _boxBottomMargin: 0,
        _boxWidth: 0,
        setDialogCenter: function () {
            var i, o, s;
            i = 0, o = 0, "none" != this.$contentPane.css("display") && (i = this.$content.outerHeight() + 15 || 0, o = this.$contentPane.height() || 0);
            var e = this.$content.children();
            if (0 != e.length) {
                var a = parseInt(e.eq(0).css("margin-top"));
                a && (i += a)
            }
            0 == o && (o = i);
            var c, r = t(n).height(), l = (r - (c = this.$body.outerHeight() - o + i)) / 2;
            c > r - (this.offsetTop + this.offsetBottom) || !this.alignMiddle ? (s = {
                "margin-top": this.offsetTop, "margin-bottom": this.offsetBottom
            }, this._boxTopMargin = this.offsetTop, this._boxBottomMargin = this.offsetBottom, t("body").addClass("jconfirm-no-scroll-" + this._id)) : (s = {
                "margin-top": l, "margin-bottom": this.offsetBottom
            },
                this._boxTopMargin = l,
                this._boxBottomMargin = this.offsetBottom,
                t("body").removeClass("jconfirm-no-scroll-" + this._id)),
                this.$contentPane.css({height: i}).scrollTop(0),
                this.$body.css(s),
                this.setDrag()
        },
        _unwatchContent: function () {
            clearInterval(this._timer)
        },
        close: function () {
            var i = this;
            "function" == typeof this.onClose && this.onClose(), this._unwatchContent(), clearInterval(this.imageLoadInterval), t(n).unbind("resize." + this._id), t(n).unbind("keyup." + this._id), t(n).unbind("jcKeyDown." + this._id), this.draggable && (t(n).unbind("mousemove." + this._id), t(n).unbind("mouseup." + this._id), this.$titleContainer.unbind("mousedown")), t("body").removeClass("jconfirm-no-scroll-" + this._id), this.$body.addClass(this.closeAnimationParsed), this.$jconfirmBg.addClass("jconfirm-bg-h");
            var o = "none" == this.closeAnimation ? 1 : this.animationSpeed;
            return i.$el.removeClass(i.loadedClass), setTimeout(function () {
                i.$el.remove();
                jconfirm.instances;
                var o = jconfirm.instances.length - 1;
                for (o; o >= 0; o--) jconfirm.instances[o]._id == i._id && jconfirm.instances.splice(o, 1);
                if (!jconfirm.instances.length && i.scrollToPreviousElement && jconfirm.lastFocused && jconfirm.lastFocused.length && t.contains(document, jconfirm.lastFocused[0])) {
                    var s = jconfirm.lastFocused;
                    if (i.scrollToPreviousElementAnimate) {
                        var e = t(n).scrollTop(), a = jconfirm.lastFocused.offset().top, c = t(n).height();
                        if (a > e && a < e + c) {
                            s.focus();
                        }
                        else {
                            var r = a - Math.round(c / 3);
                            t("html, body").animate({scrollTop: r}, i.animationSpeed, "swing", function () {
                                s.focus()
                            })
                        }
                    }
                    else {
                        s.focus();
                    }
                    jconfirm.lastFocused = !1
                }
                "function" == typeof i.onDestroy && i.onDestroy()
                t("body").removeClass("jconfirm-no-scroll-" + i._id);
            }, .4 * o), !0
        },
        open: function () {
            return this._buildHTML(), this._bindEvents(), this._open(), !0
        },
        _open: function () {
            var t = this;
            "function" == typeof t.onOpenBefore && t.onOpenBefore(), this.$body.removeClass(this.animationParsed), this.$jconfirmBg.removeClass("jconfirm-bg-h"), this.$body.focus(), setTimeout(function () {
                t.$body.css(t._getCSS(t.animationSpeed, 1)), t.$body.css({"transition-property": t.$body.css("transition-property") + ", margin"}), t._modalReady.resolve(), "function" == typeof t.onOpen && t.onOpen(), t.$el.addClass(t.loadedClass)
            }, this.animationSpeed)
        },
        loadedClass: "jconfirm-open",
        isClosed: function () {
            return "" === this.$el.css("display")
        },
        isOpen: function () {
            return !this.isClosed()
        },
        toggle: function () {
            this.isOpen() ? this.close() : this.open()
        }
    }, jconfirm.instances = [], jconfirm.lastFocused = !1, jconfirm.pluginDefaults = {
        template: '<div class="jconfirm">' +
            '<div class="jconfirm-bg jconfirm-bg-h"></div>' +
            '<div class="jconfirm-scrollpane">' +
            '<div class="jc-bs3-container">' +
            '<div class="jc-bs3-row">' +
            '<div class="jconfirm-box-container">' +
            '<div class="jconfirm-box" role="dialog" aria-labelledby="labelled" tabindex="-1">' +
            '<div class="jconfirm-closeIcon">&times;</div>' +
            '<div class="jconfirm-title-c">' +
            '<span class="jconfirm-icon-c"></span>' +
            '<span class="jconfirm-title"></span>' +
            '</div>' +
            '<div class="jconfirm-content-pane">' +
                '<div class="jconfirm-content"></div>' +
            '</div>' +
            '<div class="jconfirm-buttons"></div>' +
            '<div class="jconfirm-clear">' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>',
        title: "",
        titleClass: "",
        type: "default",
        typeAnimated: !0,
        draggable: !1,
        alignMiddle: !0,
        content: "",
        buttons: {},
        defaultButtons: {
            ok: {
                action: function () {
                }
            }, close: {
                action: function () {
                }
            }
        },
        contentLoaded: function () {
        },
        icon: "",
        bgOpacity: null,
        theme: "light",
        animation: "zoom",
        closeAnimation: "scale",
        animationSpeed: 400,
        animationBounce: 1.2,
        escapeKey: !0,
        rtl: !1,
        container: "body",
        containerFluid: !1,
        backgroundDismiss: !1,
        backgroundDismissAnimation: "shake",
        autoClose: !1,
        closeIcon: null,
        closeIconClass: !1,
        watchInterval: 100,
        columnClass: "col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1",
        boxWidth: "50%",
        scrollToPreviousElement: !0,
        scrollToPreviousElementAnimate: !0,
        useBootstrap: !0,
        offsetTop: 50,
        offsetBottom: 50,
        dragWindowGap: 15,
        bootstrapClasses: {container: "container", containerFluid: "container-fluid", row: "row"},
        onContentReady: function () {
        },
        onOpenBefore: function () {
        },
        onOpen: function () {
        },
        onClose: function () {
        },
        onDestroy: function () {
        },
        onAction: function () {
        }
    };
    var i = !1;
    t(n).on("keydown", function (o) {
        if (!i) {
            var s = !1;
            t(o.target).closest(".jconfirm-box").length && (s = !0), s && t(n).trigger("jcKeyDown"), i = !0
        }
    }), t(n).on("keyup", function (t) {
        i = !1
    })
}(jQuery, window);

